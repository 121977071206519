<template>
    <NuxtLayout name="default">
        <GameMenu name="Hood Modded" image="/images/games/icons/hood-modded.webp" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Stomp FX', to: '/hood-modded/stomp-fx' },
    { title: 'Tag FX', to: '/hood-modded/tag-fx' },
    { title: 'Trade Calculator', to: '/hood-modded/calculate' },
    { title: 'Trade Ads', to: '/hood-modded/ads' },
    { title: 'Value Changes', to: '/hood-modded/value-changes' },
    { title: 'Leaks', to: '/hood-modded/leaks' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Hood Modded | RoValue` : 'Hood Modded - RoValue'
    }
})
</script>
